import colors from "./colors";

export const publiReportStyle = `
  .publi {
    padding: 10px 15px 10px;
    background-color: ${colors.gold};
    box-sizing: border-box;

    &.publiMargin {
      margin-right: 20px;
    }

    .ago {
      color: ${colors.placeholderGrey};
    }

    span,
    h2 a,
    h2 span {
      color: ${colors.white}
    }
    svg path {
      fill: ${colors.white}
    }
    &:hover {
      opacity: 0.8;
    }

    &.flat {
      span {
        margin-top: 0;
      }
      &:before {
        border-color: transparent;
      }
    }
  }
`;
